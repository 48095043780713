<template>
  <div>
    <Html :lang="actualLocale" :dir="head.htmlAttrs.dir">
      <template v-for="link in headLinks" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
      <slot />
      <WidgetsWhatsappLink v-if="showWhatsapp" />
    </Html>
  </div>
</template>

<script setup>
  import { localeMappings } from '~/constants/locales';

  const { locale } = useI18n();
  const showWhatsapp = useLaunchDarkly('mx-whatsapp');
  const actualLocale = localeMappings[locale.value];

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
  });

  const headLinks = computed(() => {
    const i18nHeadModifiedCanonicalLink = head.value.link
      ? head.value.link.map((link) => {
          link.href = `${link.href}/`.replace(/([^:]\/)\/+/g, '$1');
          if (link.rel === 'canonical') {
            const hrefLangLink = {
              href: link.href,
              hreflang: 'x-default',
              'data-hid': 'x-default',
              rel: 'alternate',
            };
            return [link, hrefLangLink];
          } else if (link.hreflang) {
            return [link, ''];
          }
          return [link, ''];
        })
      : null;

    const i18nHeadModifiedFiltered = i18nHeadModifiedCanonicalLink?.length
      ? i18nHeadModifiedCanonicalLink.filter((link) =>
          link[0].rel === 'canonical' ||
          link[0].hreflang === 'en' ||
          link[1].hreflang === 'x-default'
            ? [link[0], link[1]]
            : ''
        )
      : null;

    return [
      ...(i18nHeadModifiedFiltered ? i18nHeadModifiedFiltered[0] : ''),
      ...(i18nHeadModifiedFiltered && i18nHeadModifiedFiltered.length > 1
        ? i18nHeadModifiedFiltered[1]
        : ''),
    ];
  });
</script>
